(function ($) {
  $('.parallax-container').each(function() {
    var that = $(this);
    $(window).on("scroll", function() {
      var viewportHeight = $(window).height();
      var elContainerPosition = that.offset().top;
      var parentHeight = that.outerHeight();
      var offset = $(window).scrollTop() - elContainerPosition;
      var el = that.find(".parallax-el");

      el.each(function() {
        var ratio = $(this).data("ratio");
        var topPosition = -((offset*ratio)/parentHeight)*10;

        if(offset >= elContainerPosition && offset <= parentHeight) {
          $(this).css({
            "top": topPosition+"px"
          });
        }
      });
    });
  });
})(jQuery);