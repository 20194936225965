(function ($) {
  $('.os-animation, .staggered-animation-container').each(function() {
    function onScrollInit( items, trigger ) {
      items.each( function() {
        var osElement = $(this),
            osAnimationClass = osElement.attr('data-os-animation'),
            osAnimationDelay = osElement.attr('data-os-animation-delay');

        osElement.css({
          '-webkit-animation-delay':  osAnimationDelay,
          '-moz-animation-delay':     osAnimationDelay,
          'animation-delay':          osAnimationDelay
        });

        var osTrigger = ( trigger ) ? trigger : osElement;

        osTrigger.waypoint(function() {
          osElement.addClass('animated').addClass(osAnimationClass);
        },{
          triggerOnce: true,
          offset: '90%'
        });
      });
    }

    onScrollInit( $('.os-animation') );
    $('.staggered-animation-container').each(function() {
      onScrollInit( $(this).find('.staggered-animation'), $(this) );
    });
  });
})(jQuery);