
(function ($) {
  $('.modal-link').each(function () {
    $('.modal-link').click(function (e) {
      e.preventDefault();
      var toOpen = $(this).attr("href");
      $(toOpen).addClass("open");
    });
    $('.modal .close').click(function () {
      $('.modal').removeClass('open');
    });
    $('.modal-content').click(function (e) {
      e.stopPropagation();
    });
    $('.modal').click(function () {
      $('.modal').removeClass('open');
    });
  });

  $('#modal-info').each(function () {
    var that = $(this);

    setTimeout(function () {
      $("body").addClass("modal-open");
      that.addClass("open");
    }, 1000);
    that.find('.close').click(function () {
      that.removeClass('open');
      $("body").removeClass("modal-open");
    });
    that.find('.modal-content').click(function (e) {
      e.stopPropagation();
    });
    that.click(function () {
      that.removeClass('open');
      $("body").removeClass("modal-open");
    });
  });
})(jQuery);
